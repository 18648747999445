import { proxyEnum } from '~/utils/enum-util';
import { REG_ID } from '~/utils/reg';

// vplus认证类名
const AuthClass = proxyEnum({
  NOT_CERTIFIED: { label: '未认证', value: 0, custom: 'unAuth' },
  BASE_SUBMITTED: { label: '审核中', value: 1, custom: 'authing' },
  CERTIFIED: { label: '已认证', value: 2, custom: 'auth' },
  AUTH_FAILDED: { label: '未通过', value: 9, custom: 'unAuth' },
});

// 移动云认证类名
const MobileAuthClass = proxyEnum({
  NotCERTIFIED: { label: '未认证', value: 0, custom: 'unAuth' },
  CERTIFICATION: { label: '认证中', value: 1, custom: 'authing' },
  SUCCESS: { label: '已认证', value: 2, custom: 'auth' },
  FAILED: { label: '未通过', value: 9, custom: 'unAuth' },
});

// 阿里云服务化认证postMessage状态
enum MessageType {
  // 页面初始化
  INIT = 'aliyun_fast_auth_init',
  // 暂不认证
  LATER = 'aliyun_fast_auth_later',
  // 选择其他认证方式
  ANOTHER_AUTHC_WAY = 'aliyun_fast_auth_another_authc_way',
  // 认证成功
  AUTHC_SUCCESS = 'aliyun_fast_auth_success',
  // 认证失败
  AUTHC_FAIL = 'aliyun_fast_auth_fail',
  // 重新认证
  RE_AUTHC = 'aliyun_fast_auth_re_authc',
  // 打开支付宝认证页
  OPEN_ALIPAY = 'aliyun_fast_auth_open_alipay'
}

// 登陆验证返回结果
enum UserLoginResult {
  // 登陆成功
  SUCCESS = 'login_success',
  // 需要进行短信登录
  NEED_MESSAGE = 'need_message',
  // 用户名或密码错误
  ERROR = 'user_undefined_or_pwd_fail',
  // 短信验证成功
  MSG_CODE_SUCCESS = 'msg_code_right'
}
// 登陆状态码
enum UserLoginStatus {
  // 需要短信验证
  NEED_MSG = 10001,
  // 短信验证错误
  MSG_FAIL = 10002,
  // 短信过期失效
  MSG_TIME_OUT = 10003,
  // 用户或密码错误
  ACCOUNT_ERROR = 10004,
  // 登陆成功
  LOGIN_SUCCESS = 200
}

const ICTypeEnum = proxyEnum({
  ID_CARD: { label: '身份证', value: '6', custom: REG_ID },
  // 因合同管理需求 必须要求企业认证的身份证号 所以先注释其他类型证件
  // ORGANIZATION: { label: '组织机构代码证', value: '1', custom: REG_ORG_CODE },
  // RESIDENCE_BOOKLET: { label: '户口本', value: '7', custom: REG_HRB_ID },
  // PASSPORT: { label: '护照', value: '10', custom: REG_PASS_PORT },
});

export {
  AuthClass,
  MessageType,
  UserLoginResult,
  UserLoginStatus,
  ICTypeEnum,
  MobileAuthClass
};