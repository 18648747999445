export enum StorageKeys {
  TOKEN = 'userToken',
  LOCAL_USER = 'localUser',
  TENANT_ID = 'tenantId',
  SAFARI_TIP = 'safariTip',
  FIRST_USER = 'firstUser',
  PASS_CODE = 'passCode',
  PHONE_CODE = 'phoneCode',
  EMAIL_CODE = 'emailCode',
  MOBILE_TOKEN = 'mobileToken'
}
