import { RouteRecordRaw } from 'vue-router';
import { individualBaseMeta } from './router-meta';
// import { createMultipRouterView } from '~/utils/route-generate';
import { OrderRoutes, RenewalRoutes } from './goods-routes';

export const IndividualRouter: RouteRecordRaw[] = [
  {
    path: '/Individual',
    name: 'individual-layout',
    redirect: '/Individual/User',
    component: () => import('~/views/individual/Index.vue'),
    meta: {
      requireAuth: true,
      footerHidden: true,
    },
    children: [
      // 用户中心 主页
      {
        path: 'User',
        name: 'individual-user-view',
        redirect: '/Individual/User/Index',
        component: () => import('~/views/individual/pages/home/View.vue'),
        meta: {
          requireAuth: true
        },
        menuProps: {
          name: '主页',
          icon: 'icon-home',
          show: true,
        },
        children: [
          {
            path: 'Index',
            name: 'individual-user-index',
            component: () => import('~/views/individual/pages/home/Index.vue'),
            meta: individualBaseMeta
          },
          {
            path: 'Cash',
            name: 'individual-user-cash',
            component: () => import('~/views/individual/pages/recharge/Cash.vue'),
            meta: individualBaseMeta
          }
        ]
      },
      // 咨询管理
      {
        path: 'Consult',
        name: 'individual-consult',
        component: () => import('~/views/individual/pages/Consult.vue'),
        meta: individualBaseMeta,
        menuProps: {
          name: '咨询管理',
          icon: 'icon-message',
          needExpend: true,
        },
      },
      // 订单管理
      {
        path: 'Order',
        name: 'individual-order',
        component: () => import('~/views/individual/pages/Order.vue'),
        meta: individualBaseMeta,
        menuProps: {
          name: '订单管理',
          icon: 'icon-order',
          needExpend: true,
        },
        children: OrderRoutes
      },
      // 续费管理
      {
        path: 'Renew',
        name: 'individual-renew',
        component: () => import('~/views/individual/pages/Renew.vue'),
        meta: individualBaseMeta,
        menuProps: {
          name: '续费管理',
          icon: 'icon-inquiry-template',
          needExpend: true,
        },
        children: RenewalRoutes
      },
      // 充值管理
      {
        path: 'Recharge',
        name: 'individual-recharge',
        redirect: '/Individual/Recharge/Detail',
        component: () => import('~/views/individual/pages/recharge/View.vue'),
        meta: individualBaseMeta,
        menuProps: {
          name: '充值管理',
          icon: 'icon-wallet',
          needExpend: true,
        },
        children: [
          {
            path: 'Detail',
            name: 'individual-recharge-detail',
            component: () => import('~/views/individual/pages/recharge/RechargeDetail.vue'),
            meta: individualBaseMeta,
            menuProps: {
              name: '充值明细',
              show: true,
            }
          },
          {
            path: 'ExpenseDetail',
            name: 'individual-recharge-expense-detail',
            component: () => import('~/views/individual/pages/recharge/ExpenseDetail.vue'),
            meta: individualBaseMeta,
            menuProps: {
              name: '支出明细',
              show: true,
            }
          }
        ]
      },
      // 合同管理
      // {
      //   path: 'Contract',
      //   name: 'individual-contract',
      //   component: () => import('~/views/individual/pages/contract/Contract.vue'),
      //   menuProps: {
      //     name: '合同管理',
      //     icon: 'icon-image-text',
      //     show: true,
      //   },
      //   meta: individualBaseMeta,
      // },
      // 发票管理
      // {
      //   path: 'Invoice',
      //   name: 'individual-invoice-view',
      //   redirect: '/Individual/Invoice/List',
      //   component: () => import('~/views/individual/pages/invoice/View.vue'),
      //   meta: individualBaseMeta,
      //   menuProps: {
      //     name: '发票管理',
      //     icon: 'icon-invoice',
      //     needExpend: true
      //   },
      //   children: [
      //     // 发票管理
      //     {
      //       path: 'List',
      //       name: 'individual-invoice-list',
      //       redirect: '/Individual/Invoice/List/Index',
      //       meta: individualBaseMeta,
      //       menuProps: {
      //         name: '发票管理',
      //         show: true,
      //       },
      //       children: [
      //         // 发票概览
      //         {
      //           path: 'Index',
      //           name: 'individual-invoice-list-index',
      //           component: () => import('~/views/individual/pages/invoice/Index.vue'),
      //           meta: individualBaseMeta
      //         },
      //         // 开发票
      //         {
      //           path: 'Invoicing',
      //           name: 'individual-invoice-list-Invoicing',
      //           component: () => import('~/views/individual/pages/invoice/Invoicing.vue'),
      //           meta: {
      //             requireAuth: true,
      //             authentication: true,
      //           },
      //         },
      //         // 已申请开票
      //         {
      //           path: 'Record',
      //           name: 'individual-invoice-list-Record',
      //           component: () => import('~/views/individual/pages/invoice/Record.vue'),
      //           meta: {
      //             requireAuth: true,
      //             authentication: true,
      //           },
      //         },
      //       ]
      //     },
      //     // 发票信息管理
      //     {
      //       path: 'Info',
      //       name: 'individual-invoice-Info',
      //       component: () => import('~/views/individual/pages/invoice/Info.vue'),
      //       meta: individualBaseMeta,
      //       menuProps: {
      //         name: '发票信息管理',
      //         show: true,
      //       },
      //     },
      //   ]
      // },
      // 地址管理
      // {
      //   path: 'Address',
      //   name: 'individual-address',
      //   component: () => import('~/views/individual/pages/Address.vue'),
      //   menuProps: {
      //     name: '地址管理',
      //     icon: 'icon-company',
      //     show: true,
      //   },
      //   meta: individualBaseMeta
      // },
      // 安全管理
      {
        path: 'Safety',
        name: 'individual-safety',
        redirect: '/Individual/Safety/Index',
        component: () => import('~/views/individual/pages/safety/View.vue'),
        meta: individualBaseMeta,
        menuProps: {
          name: '安全管理',
          icon: 'i-ep-lock',
          show: true
        },
        children: [
          {
            path: 'Index',
            name: 'individual-safety-index',
            component: () => import('~/views/individual/pages/safety/Index.vue'),
            meta: individualBaseMeta,
          },
          {
            path: 'Authentication',
            name: 'individual-safety-authentication',
            component: () => import('~/views/individual/pages/safety/Authentication.vue'),
            meta: individualBaseMeta,
          },
          {
            path: 'Modify',
            name: 'individual-safety-modify-info',
            component: () => import('~/views/individual/pages/safety/ModifyInfo.vue'),
            meta: individualBaseMeta,
          }
        ]
      },
      // 消息管理
      {
        path: 'Message',
        name: 'individual-message',
        redirect: '/Individual/Message/List',
        component: () => import('~/views/individual/pages/message/View.vue'),
        meta: individualBaseMeta,
        menuProps: {
          name: '消息管理',
          icon: 'icon-notice',
          show: true,
        },
        children: [
          {
            path: 'List',
            name: 'individual-messaage-list',
            component: () => import('~/views/individual/pages/message/List.vue'),
            meta: individualBaseMeta
          },
          {
            path: 'Detail',
            name: 'individual-message-detail',
            component: () => import('~/views/individual/pages/message/Detail.vue'),
            meta: individualBaseMeta
          }
        ]
      },
      // 卡券管理
      // {
      //   path: 'Coupon',
      //   name: 'individual-coupon',
      //   redirect: '/Individual/Coupon/Stored',
      //   component: () => import('~/views/individual/pages/card-coupon/View.vue'),
      //   meta: individualBaseMeta,
      //   menuProps: {
      //     name: '卡券管理',
      //     icon: 'icon-coupons',
      //     needExpend: true
      //   },
      //   children: [
      //     // 储值卡
      //     {
      //       path: 'Stored',
      //       name: 'individual-coupon-stored',
      //       redirect: '/Individual/Coupon/Stored/List',
      //       component: createMultipRouterView('individual-coupon-stored'),
      //       meta: individualBaseMeta,
      //       menuProps: {
      //         name: '储值卡',
      //         show: true,
      //       },
      //       children: [
      //         {
      //           path: 'List',
      //           name: 'individual-coupon-stored-list',
      //           component: () => import('~/views/individual/pages/card-coupon/stored-value-card/List.vue'),
      //           meta: individualBaseMeta,
      //         },
      //         {
      //           path: ':id',
      //           name: 'individual-coupon-stored-detail',
      //           component: () => import('~/views/individual/pages/card-coupon/stored-value-card/Detail.vue'),
      //           meta: individualBaseMeta,
      //         }
      //       ]
      //     },
      //     // 代金券
      //     {
      //       path: 'Voucher',
      //       name: 'individual-coupon-voucher',
      //       component: () => import('~/views/individual/pages/card-coupon/Voucher.vue'),
      //       meta: individualBaseMeta,
      //       menuProps: {
      //         name: '代金券',
      //         show: true,
      //       },
      //     },
      //     // 优惠券
      //     // {
      //     //   path: 'Coupon',
      //     //   name: 'individual-coupon-coupon',
      //     //   component: () => import('~/views/individual/pages/card-coupon/Coupon.vue'),
      //     //   meta: individualBaseMeta,
      //     //   menuProps: {
      //     //     name: '优惠券',
      //     //     show: true,
      //     //   },
      //     // }
      //   ]
      // }
    ]
  }
];

export const IndividualOtherRouter: RouteRecordRaw[] = [
  {
    path: 'contract-sign/:id',
    name: 'contract-sign',
    props: {
      disabled: false
    },
    component: () => import('~/views/individual/pages/contract/ContractSign.vue'),
    meta: {
      requireAuth: true,
      footerHidden: true,
    },
  },
  {
    path: 'contract-detail/:id',
    name: 'contract-detail',
    props: {
      disabled: true
    },
    component: () => import('~/views/individual/pages/contract/ContractSign.vue'),
    meta: {
      requireAuth: true,
      footerHidden: true,
    },
  }
];