import CryptoJS from 'crypto-js';


// 前后端数据传输的密钥
const cencPassword = 'wsjjcloudxmobile';

/**
 * AES 加密
 * @param {*} src  明文
 * @param {*} keyWord  密钥
 * @returns 密文
 */
export function aesEncrypt (src: string) {
  const key = CryptoJS.enc.Utf8.parse(cencPassword);
  // 加密
  const encrypted = CryptoJS.AES.encrypt(src, key, {
    iv: key,
    mode: CryptoJS.mode.CFB,
    padding: CryptoJS.pad.NoPadding
  });
  return encrypted.toString();
}

/**
 *  解密
 * @param {*} result 密文
 * @param {*} keyWord 密钥
 * @returns 明文
 */
export function aesDecrypt (result: string, keyWord: string) {
  const key = CryptoJS.enc.Latin1.parse(keyWord);
  const iv = key;
  // 解密逻辑
  const decryptd = CryptoJS.AES.decrypt(result, key, {
    iv,
    mode: CryptoJS.mode.CFB,
    padding: CryptoJS.pad.NoPadding
  });

  return decryptd.toString(CryptoJS.enc.Utf8);
}

/**
 * Base64 加密
 * @param {*} src  明文
 * @returns 密文
 */
export function base64Encrypt (src: string) {
  const encodedWord = CryptoJS.enc.Utf8.parse(src);
  return CryptoJS.enc.Base64.stringify(encodedWord);
}
