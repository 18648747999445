import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta, individualBaseMeta } from '~/router/router-meta';
import { createGoodsBusinessRoute } from '~/utils/route-generate';
import { getQueryLink } from '~/utils/util';

const constant = () => import('~/views/goods/shadowless-cloud/shadowless-cloud/constant');
const tableConfig = () => import('~/views/goods/shadowless-cloud/shadowless-cloud/table.config');

// CloudCC CRM 购买
export const purchase: RouteRecordRaw[] = [
  // 新购
  {
    path: 'ShadowlessCloud',
    name: 'shadowless-cloud-purchase',
    component: () => import('~/views/goods/shadowless-cloud/shadowless-cloud/Purchase.vue'),
    meta: purchaseMeta
  },
  // 升配/续费
  {
    path: 'ShadowlessCloudRenew',
    name: 'shadowless-cloud-renew',
    component: () => import('~/views/goods/shadowless-cloud/shadowless-cloud/Renew.vue'),
    meta: purchaseMeta
  }
];

// CloudCC CRM 订单列表
export const order: RouteRecordRaw = createGoodsBusinessRoute<'order'>({
  path: 'ShadowlessCloud',
  name: 'shadowless-cloud-order-list',
  meta: individualBaseMeta,
  menuProps: { name: '无影云电脑', show: true },
  component: 'order',
  props: {
    source: 2,
    externalCode: 'wy_cloud',
    verifyOrder: true,
    jsonLabel: { path: constant, property: 'detailLabelConfig' },
    paymentRoute: row => {
      const { ptId, orderNum } = row;
      const path = '/ShadowlessCloud';
      const query = { id: ptId, orderNum };
      const link = getQueryLink({ path, query });
      return link;
    },
    navigator: {
      list: [
        { text: '订单管理' },
        { text: '无影云电脑' }
      ]
    },
  }
});

// CloudCC CRM 续费列表
export const renewal: RouteRecordRaw = createGoodsBusinessRoute<'renewal'>({
  path: 'ShadowlessCloud',
  name: 'shadowless-cloud-renew-list',
  meta: individualBaseMeta,
  menuProps: { name: '无影云电脑', show: true },
  component: 'renewal',
  props: {
    externalCode: 'wy_cloud',
    columns: { path: tableConfig, property: 'instanceColumns' },
    navigator: {
      list: [
        { text: '续费管理' },
        { text: '无影云电脑' }
      ]
    },
  }
});