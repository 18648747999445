import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta } from '~/router/router-meta';

export const purchase: RouteRecordRaw = {
  path: '/MobileBms',
  name: 'mobile-bms',
  redirect: '/MobileBms/Purchase',
  children: [
    // 新购
    {
      path: 'Purchase',
      name: 'bms-purchase-new',
      component: () => import('~/views/goods/mobile-cloud/purchase/bms/new/index.vue'),
      meta: purchaseMeta,
    },
  ]
};
