import { RouteRecordRaw } from 'vue-router';
import { individualBaseMeta, purchaseMeta } from '~/router/router-meta';
import { createGoodsBusinessRoute } from '~/utils/route-generate';
import { ExternalCodeType } from '~/constant/goods-enum';

const constant = () => import('~/views/goods/ecology-cloud/cloud-box/constant');

export const purchase: RouteRecordRaw[] = [
  {
    path: 'CloudBox',
    name: 'cloud-box-purchase',
    meta: purchaseMeta,
    redirect: '/CloudBox/Purchase',
    children: [
      // 新购
      {
        path: 'Purchase',
        name: 'cloud-box-purchase-new',
        meta: purchaseMeta,
        component: () => import('~/views/goods/ecology-cloud/cloud-box/Purchase.vue'),
      },
      // 续费
      {
        path: 'Renewal/:orderId',
        name: 'cloud-box-purchase-renewal',
        meta: purchaseMeta,
        component: () => import('~/views/goods/ecology-cloud/cloud-box/Renewal.vue'),
      },
      // 结算
      createGoodsBusinessRoute<'settlement'>({
        path: 'Settlement/:orderNum',
        name: 'cloud-box-settlement',
        meta: purchaseMeta,
        component: 'settlement',
        props: {
          orderRoute: '/Individual/Order/EcologyCloud/CloudBox',
          orderDetailJsonConfig: { path: constant, property: 'CloudBoxDetailJSON' },
        }
      })
    ]
  },
];

export const order: RouteRecordRaw = createGoodsBusinessRoute<'order'>({
  path: 'CloudBox',
  name: 'cloud-box-order',
  meta: individualBaseMeta,
  menuProps: { name: '云盒', show: true },
  component: 'order',
  props: {
    filterTable: {
      cache: true
    },
    source: 2,
    externalCode: ExternalCodeType.CLOUD_BOX,
    jsonLabel: { path: constant, property: 'CloudBoxDetailJSON' },
    paymentRoute: ({ orderNum }) => `/CloudBox/Settlement/${orderNum}`,
    navigator: {
      list: [
        { text: '订单管理' },
        { text: '云盒' }
      ]
    },
  },
});

export const renewal: RouteRecordRaw = createGoodsBusinessRoute<'renewal'>({
  path: 'CloudBox',
  name: 'cloud-box-renewal',
  meta: individualBaseMeta,
  menuProps: { name: '云盒', show: true },
  component: 'renewal',
  props: {
    externalCode: ExternalCodeType.CLOUD_BOX,
    columns: { path: constant, property: 'CloudBoxRenwalColumns' },
    navigator: {
      list: [
        { text: '续费管理' },
        { text: '云盒' },
      ]
    }
  }
});