import { APIRequest } from '~/api/config';
import qs from 'qs';
import {
  AuthenticationForm,
  User,
  UserToken,
  Login,
  UserLoginParams,
  SaveUserInfoParams,
  UpdatePasswordParams,
  UserAuthticationStatuss
} from '~/models/user';
import { CurrentParams } from '~/models/response-type';
import { aesEncrypt } from '~/utils/encryption';

const API_USER = {
  // 发送验证码
  sendMessage (phone: string) {
    return APIRequest.get<{ msg: string }>({
      url: `/admin/mobile/validateCode/${phone}`,
      isNeedLogin: false,
      needDeepData: false
    });
  },
  // 注册
  registerUser (loginObj: Login) {
    return APIRequest.post({
      url: 'admin/register/user',
      data: loginObj,
      isNeedLogin: false
    });
  },
  // 登录
  LoginUser (userForm: UserLoginParams) {
    const { verification, password: formPassword } = userForm;
    const password = aesEncrypt(formPassword);
    const headers = {
      'Authorization': 'Basic Y2xvdWQ6Y2xvdWQ=',
      'Content-Type': 'application/x-www-form-urlencoded',
      'verification': verification || ''
    };
    if (!verification) {
      Reflect.deleteProperty(headers, 'verification');
    }
    return APIRequest.post<UserToken>({
      url: 'auth/oauth2/token',
      data: qs.stringify({ ...userForm, password }),
      headers,
      isNeedLogin: false,
      needDeepData: false,
      notReport: true
    });
  },
  // 注销
  cancellationUser () {
    return APIRequest.delete<boolean>({ url: 'auth/token/logout' });
  },
  // 获取用户信息
  getUserInfo () {
    return APIRequest.get<{ sysUser: User }>({
      url: 'admin/user/info',
      retry: 2,
      timeout: 20000
    });
  },
  // 领取无影云电脑
  createOrderForGiven () {
    return APIRequest.post({
      url: '/eco/ecowyhost/createOrderForGiven',
    });
  },
  // 获取用户折扣
  getUserDiscount (params: CurrentParams) {
    params.current = 1;
    return Promise.resolve({ records: [], total: 0 });
  },
  // 用户忘记密码获取手机号
  getPhoneForForget (username: string) {
    return APIRequest.get<string>({
      url: '/admin/SysSecureController/getPhoneByUserName',
      params: { username },
      isNeedLogin: false
    });
  },
  // 忘记密码获取验证码
  getPhoneCodeForget (username: string,) {
    return APIRequest.get<boolean>({
      url: '/admin/SysSecureController/SendVerificationCode',
      params: { username },
      isNeedLogin: false
    });
  },
  // 验证忘记密码的手机验证码
  checkForgetPhoneCode (username: string, code: string) {
    return APIRequest.get<boolean>({
      url: '/admin/SysSecureController/checkVerificationCode',
      params: { username, code },
      isNeedLogin: false
    });
  },
  // 开始修改忘记密码的密码
  updateForgetPassword (passWord: string, userName: string) {
    return APIRequest.update<boolean>({
      url: '/admin/SysSecureController/setNewPassword',
      data: { passWord, userName },
      isNeedLogin: false
    });
  },
  // 修改用户信息
  saveUserInfo (data: SaveUserInfoParams) {
    return APIRequest.update<boolean>({ url: '/admin/user', data });
  },
  // 获取用户余额
  getUserBalance () {
    return Promise.resolve('0');
  },
  // 获取用户近半年产品消费
  getHalfYearBuy (params: { startTime: string, endTime: string }) {
    return APIRequest.get<string[]>({
      url: '/statistic/order/getUserExpendLine',
      params
    });
  },
  // 首次登录or异地登录获取验证码
  getPhoneByVerification (account: string) {
    return APIRequest.get<boolean>({
      url: '/blade-user/user/getPhoneByVerification',
      params: { account },
      isNeedLogin: false
    });
  },
  // 安全管理 获取脱敏后的手机号
  getPhoneForSecure () {
    return APIRequest.get<string>({
      url: '/admin/SysSecureController/getPhone'
    });
  },
  // 安全管理 身份验证 发送验证码
  sendAuthVerifyCode () {
    return APIRequest.get<string>({ url: '/admin/SysSecureController/SendingVerificationCode' });
  },
  // 安全管理 身份验证 验证短信验证码
  verifyAuthMessageCode (code: string) {
    return APIRequest.get<string>({ url: '/admin/SysSecureController/checkVerCode', params: { code } });
  },
  // 安全管理 修改密码
  updateUserPassword (data: UpdatePasswordParams) {
    return APIRequest.update<string>({ url: '/admin/SysSecureController/setPassword', data });
  },
  // 安全管理 换绑手机 发送验证码
  sendNewPhoneVerifyCode (phone: string) {
    return APIRequest.get<string>({ url: '/admin/SysSecureController/SendingNewPhoneVerificationCode', params: { phone } });
  },
  // 安全管理 校验换绑手机 短信验证码
  checkNewPhoneVerfiyCode (data: { phone: string, code: string }) {
    return APIRequest.update<string>({ url: '/admin/SysSecureController/setNewPhone', data });
  },
  // 安全管理 换绑邮箱 发送验证码
  sendNewEmailVerifyCode (email: string) {
    return APIRequest.get<string>({ url: '/admin/SysSecureController/SendingVerificationCodeByEmail', params: { email } });
  },
  // 安全管理 校验换绑邮箱 验证码
  checkNewEmailVerifyCode (data: { email: string, code: string }) {
    return APIRequest.update<string>({ url: '/admin/SysSecureController/checkEmailVerCode', data });
  },
  // 安全管理 修改手机号/邮箱
  updateUserPhoneOrEmail (params: { phone: string } | { email: string }) {
    return APIRequest.update<boolean>({ url: '/blade-user/updatePhoneOrEmail', params });
  },
  // 获取用户实名信息
  getUserRealName () {
    return APIRequest.get<AuthenticationForm>({ url: 'rncinfo/realnameinfo/getInfo' });
  },
  // 获取用户实名状态
  getUserRealNameStatus () {
    return APIRequest.get<UserAuthticationStatuss>({
      url: 'rncinfo/userrnstatus/getStatus',
      notReport: true
    });
  },
  // 用户添加实名认证
  authenticationUser (data: AuthenticationForm) {
    return APIRequest.post<boolean>({ url: 'rncinfo/realnameinfo', data });
  },
  // 用户修改实名认证信息
  updateUserRealName (data: AuthenticationForm) {
    return APIRequest.update<boolean>({ url: 'rncinfo/realnameinfo', data });
  },
  // 提交用户实名申请
  submitRealNameApplication () {
    return APIRequest.update<boolean>({ url: 'rncinfo/realnameapply/applySysStatus' });
  },
};

export default API_USER;
