import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta } from '~/router/router-meta';

export const purchase: RouteRecordRaw = {
  path: '/MobileOpNetworkWebVpc',
  name: 'mobile-vpc',
  redirect: '/MobileOpNetworkWebVpc/Purchase',
  children: [
    // 新购
    {
      path: 'Purchase',
      name: 'op-vpc-purchase-new',
      component: () => import('~/views/goods/mobile-cloud/purchase/vpc/Purchase.vue'),
      meta: purchaseMeta,
    },
  ]
};
