import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { ICON_FONT_URL } from './constant/const';
import { addLinkElement } from './utils/util';

import 'element-plus/dist/index.css';
import '@unocss/reset/normalize.css';
import 'uno.css';

// 添加图标库
ICON_FONT_URL.forEach(item => {
  addLinkElement(item);
});

const app = createApp(App);
app.use(router);
app.use(createPinia());
app.mount('#app');
