import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta, individualBaseMeta } from '~/router/router-meta';
import { getQueryLink } from '~/utils/util';
import { createGoodsBusinessRoute } from '~/utils/route-generate';
import { ExternalCodeType } from '~/constant/goods-enum';


const constant = () => import('~/views/goods/ecology-cloud/e-sign/constant');

export const purchase: RouteRecordRaw[] = [
  // 新购
  {
    path: 'ESign',
    name: 'e-sign-purchase',
    component: () => import('~/views/goods/ecology-cloud/e-sign/Purchase.vue'),
    meta: purchaseMeta
  },
  // 认证
  {
    path: 'ESignAuth',
    name: 'e-sian-auth',
    component: () => import('~/views/goods/ecology-cloud/e-sign/Auth.vue'),
    meta: { ...purchaseMeta, footerHidden: false }
  }
];

export const order: RouteRecordRaw = createGoodsBusinessRoute<'order'>({
  path: 'ESign',
  name: 'e-sign-order-list',
  meta: individualBaseMeta,
  menuProps: { name: 'e签宝智能合同(SaaS版)', show: true },
  component: 'order',
  props: {
    source: 2,
    externalCode: ExternalCodeType.E_SIGN,
    jsonLabel: { path: constant, property: 'OrderDetailJsonConfig' },
    paymentRoute: row => {
      const { ptId, orderNum } = row;
      const path = '/ESign';
      const query = { id: ptId, orderNum };
      const link = getQueryLink({ path, query });
      return link;
    },
    navigator: {
      list: [
        { text: '订单管理' },
        { text: '生态云' },
        { text: 'e签宝智能合同(SaaS版)' }
      ]
    },
  }
});
