import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta, individualBaseMeta } from '~/router/router-meta';
import { createGoodsBusinessRoute } from '~/utils/route-generate';
import { getQueryLink } from '~/utils/util';

const constant = () => import('~/views/goods/AI-test-system/AI-test-system/constant');

// wx program 购买
export const purchase: RouteRecordRaw[] = [
  // 新购
  {
    path: 'AITestSystem',
    name: 'AI-test-system-purchase',
    component: () => import('~/views/goods/AI-test-system/AI-test-system/Purchase.vue'),
    meta: purchaseMeta
  },
];

// wx program 订单列表
export const order: RouteRecordRaw = createGoodsBusinessRoute<'order'>({
  path: 'AITestSystem',
  name: 'AI-test-system-order-list',
  meta: individualBaseMeta,
  menuProps: { name: 'AI测试系统', show: true },
  component: 'order',
  props: {
    source: 2,
    externalCode: 'ai_test',
    verifyOrder: true,
    jsonLabel: { path: constant, property: 'detailLabelConfig' },
    paymentRoute: row => {
      const { ptId, orderNum } = row;
      const path = '/AITestSystem';
      const query = { id: ptId, orderNum };
      const link = getQueryLink({ path, query });
      return link;
    },
    navigator: {
      list: [
        { text: '订单管理' },
        { text: 'AI测试系统' }
      ]
    },
  }
});