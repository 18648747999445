import { defineStore } from 'pinia';
import { Dictionary } from '~/models/response-type';
import { GlobalStateType, GetGoodsMenuSign, GetGoodsItemSign } from '~/models/global-store-types';
import { HomeConfigRecord } from '~/models/essential/home-config';
import { StorageKeys } from '~/constant/storage-keys-enum';
import { customStorage } from '~/utils/storage';
import { hexToLight } from '~/utils/util';
import { GoodsType, GoodsItem } from '~/models/product/goods-list';
import API_GLOBAL from '~/api/global';
import {
  staticGoodsType,
  staticProductDictionary,
  staticProductTemplate,
  staticTenantCategory,
  staticmanufacturer
} from '~/constant/test-static';
import API_GOODS from '~/api/product';

// 获取Logo
const getSiteLogo = (logo: string) => {
  const reg = /^[http|https]/;
  if (logo && reg.test(logo)) {
    return logo;
  }
  return '';
};

// 获取产品
const getGoodsItem: GetGoodsItemSign = (productIds, allGoods, id, menuId) => {
  const goods: GoodsItem[] = [];
  for (let i = 0; i < allGoods.length; i += 1) {
    const { cateId, status, ptId } = allGoods[i];
    if (cateId === id && status !== '1' && productIds.includes(`${ptId}`)) {
      goods.push({ ...allGoods[i], from: menuId });
    }
  }
  return goods;
};

// 获取菜单
const getGoodsMenu: GetGoodsMenuSign = (productIds, goodsMenu, cateIds, allGoods, from) => {
  const menus: GoodsType[] = [];
  if (!goodsMenu.length) {
    return menus;
  }
  for (let i = 0; i < goodsMenu.length; i += 1) {
    const { id, parentId, children } = goodsMenu[i];
    // 获取最上级类型id
    const menuId = parentId === 0 ? id : from || 0;
    // 使用当前租户分类 判断是否展示产品分类
    const show = cateIds.includes(`${id}`);
    // 若当前类型有子类型 则递归获取子类型
    if (children) {
      const childMenu = getGoodsMenu(productIds, children, cateIds, allGoods, menuId);
      menus.push({ ...goodsMenu[i], show, children: childMenu });
    // 无子类型则赋值产品数组
    } else {
      const goods = getGoodsItem(productIds, allGoods, id, menuId);
      menus.push({ ...goodsMenu[i], show, goods });
    }
  }
  return menus;
};

// 获取产品字典
const getProductDic = (data: Dictionary[]) => {
  const productDic: Record<string, Dictionary> = {};
  for (let i = 0; i < data.length; i += 1) {
    const { dictKey } = data[i];
    productDic[dictKey] = data[i];
  }
  return productDic;
};

// 获取授权产品分类列表
const getAuthProductTypeList = (allGoodsType: GoodsType[], goodsProductType: Dictionary[]) => {
  // 获取有权限的产品分类
  const authGoodsType = allGoodsType.filter(item => (
    item.show && item.cateNameEn !== 'hide' && !item.cateNameEn.includes('nav_')
  ));
  // 遍历产品厂商字典 查询授权产品分类数据
  const authProductTypeList = goodsProductType.filter(goodsType => {
    return authGoodsType.find(authGoodsType => authGoodsType.name.includes(goodsType.dictValue));
  });
  return authProductTypeList;
};

export const globalStore = defineStore('global', {
  state: () => {
    const data: GlobalStateType = {
      // 租户id
      tenantId: '',
      // 部门Id
      deptId: '',
      // 部门名称
      deptName: '',
      // 代理商主题颜色
      themeColor: '',
      // 是否以header在top位置进行展示
      navPosition: true,
      // 首页产品推荐标题
      productTitle: '',
      // 首页产品推荐简介
      productDesc: '',
      // 首页解决方案介绍标题
      solutionTitle: '',
      // 首页解决方案简介
      solutionDesc: '',
      // 首页解决方案背景图
      solutionBackgroundImage: 'https://vstecscloud-file-manage.oss-cn-hangzhou.aliyuncs.com/blade-oss/8090fafd35024bd28f86823e40a5a465@:,_solutionBanner.webp',
      // 首页优势介绍标题
      advantageTitle: '',
      // 首页优势介绍简介
      advantageDesc: '',
      // 首页优势介绍背景图
      advantageBackgroundImage: 'https://vstecscloud-file-manage.oss-cn-hangzhou.aliyuncs.com/blade-oss/6619d336629b4dee9d46f6c4dd683d5f@:,_eadvaceBanner.png',
      // 联系电话
      tel: '',
      // 公网安备
      NetSec: '',
      // 页脚icp备案号
      ICP: '',
      // 电子营业执照
      electronicPhoto: '',
      electronicLink: '',
      // 平台
      platform: '',
      // 站点Logo
      siteLogo: '',
      // 登录页背景
      loginBg: 'https://dmcrm-dev.oss-cn-hangzhou.aliyuncs.com/upload/20241107/c3c8c957bec76aef7d4f13512dd0a543.png',
      // 登录页左方封面
      loginCover: '',
      // 注册背景
      registerBg: 'https://vstecscloud-file-manage.oss-cn-hangzhou.aliyuncs.com/blade-oss/bc480bf7d6294c8b9fc9722dadc87696@:,_register.aca43a04.webp',
      // 产品类型字典
      productTypeDic: {},
      // 产品厂商的字典
      goodsProductTypeDic: [],
      // 授权产品分类
      authGoodsTypeList: [],
      // 备案信息链接
      onlineCustServiceUrl: '',
      // 给当前代理商分配的产品id
      authGoodsIds: [],
      // 所有的产品
      allGoodsList: [],
      // 产品分类
      goodsMenu: [],
      // 省市区树
      areaTreeData: [],
      // 导航列表
      navList: [],
      // 产品分类树
      productCateTree: []
    };
    return data;
  },
  actions: {
    setGlobalState (data: HomeConfigRecord) {
      const {
        recommendProductTitle = '',
        recommendProductIntro = '',
        solutionTitle,
        solutionIntro,
        advantageTitle,
        advantageIntro,
        themeColor,
        customerServiceTel,
        publicSecurityCode = '',
        icpCode,
        websiteName,
        companyLogo,
        companyName = '',
        electronicLink = '',
        electronicPhoto = ''
      } = data;
      this.productTitle = recommendProductTitle;
      this.productDesc = recommendProductIntro;
      this.solutionTitle = solutionTitle;
      this.solutionDesc = solutionIntro;
      this.advantageTitle = advantageTitle;
      this.advantageDesc = advantageIntro;
      this.themeColor = themeColor;
      this.tel = customerServiceTel;
      this.NetSec = publicSecurityCode;
      this.ICP = icpCode;
      this.electronicLink = electronicLink;
      this.electronicPhoto = electronicPhoto;
      this.platform = websiteName;
      this.siteLogo = getSiteLogo(companyLogo);
      this.onlineCustServiceUrl = companyName;
    },
    // 设置全局色彩
    setGlobalTheme (color: string) {
      if (color) {
        document.body.style.setProperty('--theme-color', color);
        document.body.style.setProperty('--theme-light-color', hexToLight(color, 0.2));
        document.body.style.setProperty('--active-bg-color', hexToLight(color, 0.895));
        document.body.style.setProperty('--linear-color', hexToLight(color, 0.38));
      }
    },
    // 设置标签icon
    setIndexHtmlContent (config: HomeConfigRecord) {
      document.title = config.websiteName;
      const link = document.getElementById('link-icon');
      if (link) {
        link.setAttribute('href', config.websiteLogo || '');
      }
    },
    async init () {
      // 获取租户id
      const tenantkeys = await API_GLOBAL.getTenantPrimary();
      const { deptId, tenantId, deptName } = tenantkeys;
      // 存储租户id
      customStorage.setItem({
        key: StorageKeys.TENANT_ID,
        value: tenantkeys,
        timer: 60 * 100000000
      });
      this.tenantId = tenantId;
      this.deptId = deptId;
      this.deptName = deptName;
      // 获取系统配置
      const siteConfig = await API_GLOBAL.getSiteConfig(deptId);
      // 设置全局数据
      this.setGlobalState(siteConfig);
      // 设置标签icon
      this.setIndexHtmlContent(siteConfig);
      // 设置主题
      this.setGlobalTheme(this.themeColor);

      // 获取导航
      const navList = await API_GLOBAL.getNavList(deptId);
      this.navList = navList || [];
      // 获取产品分类树
      const tree = await API_GOODS.getCatePtList(deptId);
      this.productCateTree = tree || [];
      // 获取当前代理商被分配的产品id
      const res = await API_GLOBAL.getAllocateProductsByDeptId(deptId);
      this.authGoodsIds = res ? res.split(',') : [];
      
      // 获取产品字典
      const productTypes = staticProductDictionary;
      this.productTypeDic = getProductDic(productTypes);
      // 获取全部产品分类
      const goodsMenu = staticGoodsType;
      // 获取产品厂商字典
      this.goodsProductTypeDic = staticmanufacturer;
      // 获取所有的产品
      const allGoods = await API_GOODS.getAllGoods();
      this.allGoodsList = allGoods;
      // 顶部导航菜单数据
      this.goodsMenu = getGoodsMenu(staticProductTemplate, goodsMenu, staticTenantCategory, allGoods);
      // 获取授权产品分类列表
      this.authGoodsTypeList = getAuthProductTypeList(this.goodsMenu, this.goodsProductTypeDic);
    },
    async getAreaTreeData () {
      if (!this.areaTreeData.length) {
        const area = await API_GLOBAL.getMobileCloudAreaTreeData().catch(() => []);
        this.areaTreeData = area.filter(f => !(+f.displayFlag));
      }
      return this.areaTreeData;
    }
  }
});
