import { APIRequest } from '~/api/config';
import { PersonalListSearchParams } from '~/models/base-types';
import { HomeProductDataType } from '~/models/essential/home-config';
import { CashCoupon } from '~/models/product/cash-coupon';
import {
  GoodsList,
  GoodsDetail,
  GoodsItem,
  GoodsType,
  OrderFinallyPrice,
  OrderUntilPrice,
  TenantGoodsTypeItem,
  AgreementListModel,
  Dtinstance,
  ProductCategotyTreeType,
  ProductFuzzyQueryParamsType,
  ProductFuzzyQueryResponseType,
  ProductDetailResponseType,
  ProductUrlRecord,
  DtinstanceData
} from '~/models/product/goods-list';

const API_GOODS = {
  // 通用获取产品的购买实例列表
  getGoodsExample (params: PersonalListSearchParams, productType?: number) {
    const data = productType ? Object.assign(params, { productType: `${productType}` }) : params;
    data.current = 1;
    return Promise.resolve<Dtinstance>({
      records: [],
      total: 0,
      size: 5,
      current: 1,
      orders: [],
      optimizeCountSql: true,
      searchCount: true,
      countId: null,
      maxLimit: null,
      pages: 0
    });
  },
  getEcoInstanceList (params: PersonalListSearchParams) {
    return APIRequest.get<DtinstanceData>({
      url: 'delivery/deliveryinstance/getByUserIdPage',
      params
    });
  },
  // 获取产品的代金券
  getGoodsCash (pt: number) {
    return APIRequest.get<CashCoupon[]>({ url: 'blade-vstec/dtcashcoupon/getUserCanUseCashCoupon', params: { ptId: pt } });
  },
  // 根据orderId获取订单的详情
  getGoodsOrderDetail (orderId: string) {
    return APIRequest.get<GoodsDetail>({ url: `blade-vstec/dtorder/${orderId}` });
  },
  // 获取所有的产品分类
  getGoodsType () {
    return APIRequest.get<GoodsType[]>({ url: 'blade-vstec/ptproductscate/getListByTree', params: { status: 0 }, isNeedLogin: false });
  },
  // 获取所有的产品分类(移动云)
  getCatePtList (deptId: string) {
    return APIRequest.get<ProductCategotyTreeType[]>({
      url: '/pms/pmsproductscate/getCatePtList',
      params: { deptId },
      isNeedLogin: false
    });
  },
  // 根据分类id获取产品
  getGoodsForTypeId (cateId: number) {
    return APIRequest.get<GoodsList>({
      url: 'blade-vstec/ptproduct/getListByCateId',
      params: { current: 1, size: 999, cateId },
      isNeedLogin: false
    });
  },
  // 获取当前租户下的分类id
  getGoodsTypeForTenant (tenant: string) {
    return APIRequest.get<TenantGoodsTypeItem[]>({
      url: 'blade-vstec/ptpctrel/getRelListByTenantId',
      params: { tenantId: tenant },
      isNeedLogin: false
    });
  },
  // 根据id获取产品详情
  getGoodsInfoForId (id: number) {
    return APIRequest.get<GoodsItem>({ url: 'blade-vstec/ptproduct/getListById', params: { id }, isNeedLogin: false });
  },
  // 根据关键字获取产品
  getGoodsForKeyWord (key: string) {
    return APIRequest.get<GoodsList>({
      url: 'blade-vstec/ptproduct/getListByPage',
      params: { title: key, current: 1, size: 99 },
      isNeedLogin: false
    });
  },
  // 根据租户id获取当前产品关联模板
  getTenantGoods (tenant: string) {
    return APIRequest.get<{ productIds: string }>({
      url: 'blade-vstec/ptpdtrel/getRelListByTenantId',
      params: { tenantId: tenant },
      isNeedLogin: false
    });
  },
  // 获取全部产品
  getAllGoods () {
    return APIRequest.get<GoodsItem[]>({ url: '/pms/pmsproduct/getProductList', isNeedLogin: false });
  },
  // 获取产品详情
  getGoodsInfoForIdArr (idArr: number[]) {
    return APIRequest.post<GoodsItem[]>({ url: 'blade-vstec/ptproduct/getOnlyDetail', isNeedLogin: false, data: idArr });
  },
  // 获取未支付订单的支付金额
  getNotPayOrderPrice (params: OrderUntilPrice) {
    return APIRequest.get<string>({ url: 'blade-vstec/dtorder/amountPayable', params });
  },
  // 根据orderId获取订单价格
  getUntilOrderPrice (orderId: string) {
    return APIRequest.get<OrderFinallyPrice>({ url: 'blade-vstec/dtorder/calculateAfterHypPrice', params: { orderId } });
  },
  // 根据ptid获取产品协议
  getUAgreementListByPtId (ptId: string) {
    return APIRequest.get<AgreementListModel[]>({ url: '/pbm/agreement/getAgreementListByPtId', params: { ptId } });
  },
  // 模糊查询产品列表
  getProductByPtNameFuzzyQuery (params: ProductFuzzyQueryParamsType) {
    return APIRequest.get<ProductFuzzyQueryResponseType | null>({
      url: 'pms/pmsproduct/getProductLikePtName',
      isNeedLogin: false,
      params
    });
  },
  // 获取产品详情
  getProductDetail (id: string) {
    return APIRequest.get<ProductDetailResponseType>({ url: `pms/pmsproduct/${id}`, isNeedLogin: false });
  },
  // 通过产品code获取产品详情
  getProductUrlByCode (externalCode: string) {
    return APIRequest.get<ProductDetailResponseType>({
      url: 'pms/pmsproducturl/getUrlByExternalCode',
      params: { externalCode }
    });
  },
  // 通过产品code获取链接列表
  getProductUrlByCodeNoAuth (externalCode: string) {
    return APIRequest.get<ProductUrlRecord[]>({
      url: 'pms/pmsproducturl/getUrlByCode',
      params: { externalCode },
      isNeedLogin: false
    });
  },
  // 获取首页分类产品
  getHomeProductList (params: { deptId: string, parentCateId: string }) {
    return APIRequest.get<HomeProductDataType[]>({
      url: 'pms/pmsproductscate/getCateSecIdList',
      params,
      isNeedLogin: false
    });
  }
};
export default API_GOODS;
