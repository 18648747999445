import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta } from '~/router/router-meta';

export const purchase: RouteRecordRaw = {
  path: '/MobileVmbak',
  name: 'mobile-vmbak',
  redirect: '/MobileVmbak/Purchase',
  children: [
    // 开通
    {
      path: 'Purchase',
      name: 'vmbak-purchase-new',
      component: () => import('~/views/goods/mobile-cloud/purchase/vmbak/new/index.vue'),
      meta: purchaseMeta,
    },
    // 资源包
    {
      path: 'Resource',
      name: 'vmbak-purchase-resource',
      component: () => import('~/views/goods/mobile-cloud/purchase/vmbak/resource/index.vue'),
      meta: purchaseMeta,
    }
  ]
};
