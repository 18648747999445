import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta, individualBaseMeta } from '~/router/router-meta';
import { createGoodsBusinessRoute } from '~/utils/route-generate';
import { getQueryLink } from '~/utils/util';

const constant = () => import('~/views/goods/wx-program/wx-test-program/constant');

// wx program 购买
export const purchase: RouteRecordRaw[] = [
  // 新购
  {
    path: 'WxTestProgram',
    name: 'wx-test-program-purchase',
    component: () => import('~/views/goods/wx-program/wx-test-program/Purchase.vue'),
    meta: purchaseMeta
  },
];

// wx program 订单列表
export const order: RouteRecordRaw = createGoodsBusinessRoute<'order'>({
  path: 'WxTestProgram',
  name: 'wx-test-program-order-list',
  meta: individualBaseMeta,
  menuProps: { name: '小程序', show: true },
  component: 'order',
  props: {
    source: 2,
    externalCode: 'xcx',
    verifyOrder: true,
    jsonLabel: { path: constant, property: 'detailLabelConfig' },
    paymentRoute: row => {
      const { ptId, orderNum } = row;
      const path = '/WxTestProgram';
      const query = { id: ptId, orderNum };
      const link = getQueryLink({ path, query });
      return link;
    },
    navigator: {
      list: [
        { text: '订单管理' },
        { text: '小程序' }
      ]
    },
  }
});