import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta, individualBaseMeta } from '~/router/router-meta';
import { createGoodsBusinessRoute, createMultipRouterView } from '~/utils/route-generate';
import { ExternalCodeType } from '~/constant/goods-enum';

const constant = () => import('~/views/goods/ecology-cloud/lian-wei/constant');
const tableConfig = () => import('~/views/goods/ecology-cloud/lian-wei/table.config');
const apiLianWei = () => import('~/api/product/encology-colud/lian-wei/lian-wei');


// 联蔚FinOps
export const purchase: RouteRecordRaw[] = [
  // 联蔚FinOps产品购买页面
  {
    path: 'LianWeiProduct',
    name: 'lian-wei-product',
    component: () => import('~/views/goods/ecology-cloud/lian-wei/Purchase.vue'),
    meta: purchaseMeta
  },
  // 联蔚FinOps服务购买页面
  {
    path: 'LianWeiService',
    name: 'lian-wei-service',
    component: () => import('~/views/goods/ecology-cloud/lian-wei/Service.vue'),
    meta: purchaseMeta
  },
  // 联蔚FinOps 增配/续费页面
  {
    path: 'LianWeiRenew',
    name: 'lian-wei-renew',
    component: () => import('~/views/goods/ecology-cloud/lian-wei/Renew.vue'),
    meta: purchaseMeta
  }
];
// 联蔚FinOps订单
export const order: RouteRecordRaw = {
  path: 'LianWei',
  name: 'lian-wei-order',
  component: createMultipRouterView('lian-wei-order'),
  redirect: '/Individual/Order/EcologyCloud/LianWei',
  meta: individualBaseMeta,
  menuProps: {
    name: '联蔚数科',
    needExpend: true
  },
  children: [
    createGoodsBusinessRoute<'order'>({
      path: 'FinOps',
      name: 'lian-wei-order-finops',
      meta: individualBaseMeta,
      menuProps: { name: '联蔚FinOps', show: true },
      component: 'order',
      props: {
        source: 2,
        externalCode: ExternalCodeType.LianWei,
        verifyOrder: true,
        jsonLabel: { path: constant, property: 'orderDetailProductConfig' },
        paymentRoute: row => {
          const { ptId, orderNum } = row;
          return `/LianWeiProduct?id=${ptId}&orderNum=${orderNum}`;
        },
        navigator: {
          list: [
            { text: '订单管理' },
            { text: '联蔚数科' },
            { text: '联蔚FinOps' }
          ]
        },
      }
    }),
  ]
};
export const renewal = {
  path: 'LianWei',
  name: 'lian-wei-renewal',
  component: createMultipRouterView('lian-wei-renewal'),
  redirect: '/Individual/Renwal/EcologyCloud/LianWei',
  meta: individualBaseMeta,
  menuProps: {
    name: '联蔚数科',
    needExpend: true
  },
  children: [
    createGoodsBusinessRoute<'renewal'>({
      path: 'FinOps',
      name: 'lian-wei-renewal-finops',
      meta: individualBaseMeta,
      menuProps: { name: '联蔚FinOps', show: true },
      component: 'renewal',
      props: {
        externalCode: ExternalCodeType.LianWei,
        recordsApi: { path: apiLianWei, property: 'getLianWeiByExternalCodePage' },
        columns: { path: tableConfig, property: 'renewalColumns' },
        navigator: {
          list: [
            { text: '续费管理' },
            { text: '联蔚数科' },
            { text: '联蔚FinOps' }
          ]
        },
      }
    }),
  ]
};

