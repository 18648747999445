import { RouteRecordRaw } from 'vue-router';
import { ConsoleRoutes } from './goods-routes';

export const EssentialRoutes: RouteRecordRaw[] = [
  // 首页
  {
    path: '/Home',
    name: 'home-page',
    component: () => import('~/views/home/HomePage.vue'),
    meta: {
      headerOpacity: true,
      requireAuth: false,
    },
  },
  // 活动中心
  {
    path: 'ActivityCenter',
    name: 'activity-center',
    component: () => import('~/views/activity-center/Index.vue'),
    meta: {
      headerOpacity: true,
      requireAuth: false
    }
  },
  // 解决方案
  {
    path: '/Solution',
    name: 'solution-view',
    redirect: '/Solution/Index',
    component: () => import('~/views/solution/View.vue'),
    meta: {
      requireAuth: false,
    },
    children: [
      {
        path: 'Index',
        name: 'solution-index',
        component: () => import('~/views/solution/Index.vue'),
        meta: {
          requireAuth: false
        }
      },
      {
        path: 'Detail/:id',
        name: 'solution-detail',
        component: () => import('~/views/solution/Detail.vue'),
        meta: {
          requireAuth: false
        }
      }
    ]
  },
  // 公司介绍
  {
    path: '/CompanyIntroduction',
    name: 'company-introduction',
    component: () => import('~/views/company-introduction/Index.vue'),
    meta: {
      requireAuth: false,
    }
  },
  // 服务协议
  {
    path: '/ServiceAgreement',
    name: 'service-agreement',
    component: () => import('~/views/service-agreement/ServiceAgreement.vue'),
    meta: {
      requireAuth: false,
    }
  },
  // 登录
  {
    path: '/Login',
    name: 'login',
    component: () => import('~/views/user-enter/UserLogin.vue'),
    meta: {
      requireAuth: false,
      footerHidden: true,
      hideShoppingCart: true
    }
  },
  // 移动云ICP登录
  {
    path: '/MobileICPLogin',
    name: 'mobile-icp-login',
    component: () => import('~/views/user-enter/MobileICPLogin.vue'),
    meta: {
      hideShoppingCart: true
    }
  },
  // 注册
  {
    path: '/Register',
    name: 'register',
    component: () => import('~/views/user-enter/UserRegister.vue'),
    meta: {
      requireAuth: false,
      footerHidden: true,
      hideShoppingCart: true
    }
  },
  // 忘记密码
  {
    path: '/Forget',
    name: 'forget',
    component: () => import('~/views/user-enter/UserForget.vue'),
    meta: {
      requireAuth: false,
      hideShoppingCart: true
    }
  },
  // 控制台
  {
    path: '/Console',
    name: 'console-bar',
    component: () => import('~/views/console-bar/Console.vue'),
    meta: {
      footerHidden: true,
      requireAuth: true,
      authentication: true,
      hideShoppingCart: true
    },
    children: ConsoleRoutes
  },
  // 购物车
  {
    path: '/ShoppingCart',
    name: 'shopping-cart',
    redirect: '/ShoppingCart/List',
    component: () => import('~/views/shopping-cart/Index.vue'),
    meta: {
      footerHidden: true,
      requireAuth: true
    },
    children: [
      {
        path: 'List',
        name: 'shopping-cart-list',
        component: () => import('~/views/shopping-cart/list/List.vue'),
        meta: {
          requireAuth: true,
          hideShoppingCart: true
        }
      },
      {
        path: 'Balance',
        name: 'shopping-cart-balance',
        component: () => import('~/views/shopping-cart/balance/Balance.vue'),
        meta: {
          requireAuth: true,
          hideShoppingCart: true
        }
      }
    ]
  }
];
