import { RouteRecordRaw } from 'vue-router';
import { OrderItemAttrsType, OrderItemType } from '~/models/product/contract-lock';
import { purchaseMeta, individualBaseMeta } from '~/router/router-meta';
import { createGoodsBusinessRoute } from '~/utils/route-generate';
import { getQueryLink } from '~/utils/util';
import { ExternalCodeType } from '~/constant/goods-enum';


const constant = () => import('~/views/goods/ecology-cloud/contract-lock/constant');
const tableConfig = () => import('~/views/goods/ecology-cloud/contract-lock/table.config');

// 契约锁中大型组织电子签章工具
export const purchase: RouteRecordRaw[] = [
  // 契约锁购买页面
  {
    path: 'ContractLock/Sign',
    name: 'contract-lock-sign',
    component: () => import('~/views/goods/ecology-cloud/contract-lock/Purchase.vue'),
    props: { type: '0' },
    meta: purchaseMeta
  },
  {
    path: 'ContractLock/SignPrintControl',
    name: 'contract-lock',
    component: () => import('~/views/goods/ecology-cloud/contract-lock/Purchase.vue'),
    props: { type: '1' },
    meta: purchaseMeta
  }
];

// 契约锁 订单列表
export const order: RouteRecordRaw = createGoodsBusinessRoute<'order'>({
  path: 'ContractLock',
  name: 'contract-lock-order-list',
  meta: individualBaseMeta,
  menuProps: { name: '契约锁', show: true },
  component: 'order',
  props: {
    source: 2,
    externalCode: ExternalCodeType.CONTRACT_LOCK,
    jsonLabel: { path: constant, property: 'detailJsonConfig' },
    paymentRoute: row => {
      const { ptId, orderNum, orderItem } = row;
      const [first] = JSON.tsParse<OrderItemType[]>(orderItem);
      const { type } = JSON.tsParse<OrderItemAttrsType>(first.attrs);
      const path = type === '0' ? '/ContractLock/Sign' : '/ContractLock/SignPrintControl';
      const query = { id: ptId, orderNum };
      const link = getQueryLink({ path, query });
      return link;
    },
    navigator: {
      list: [
        { text: '订单管理' },
        { text: '生态云' },
        { text: '契约锁' }
      ]
    },
  }
});

// 契约锁 实例列表
export const renewal: RouteRecordRaw = createGoodsBusinessRoute<'renewal'>({
  path: 'ContractLock',
  name: 'contract-lock-renew-list',
  meta: individualBaseMeta,
  menuProps: { name: '契约锁', show: true },
  component: 'renewal',
  props: {
    externalCode: ExternalCodeType.CONTRACT_LOCK,
    columns: { path: tableConfig, property: 'renewalColumns' },
    navigator: {
      list: [
        { text: '续费管理' },
        { text: '生态云' },
        { text: '契约锁' }
      ]
    },
  }
});

