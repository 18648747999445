import { RouteRecordRaw } from 'vue-router';
import { individualBaseMeta, purchaseMeta } from '~/router/router-meta';
import { createGoodsBusinessRoute, createMultipRouterView } from '~/utils/route-generate';
import { ExternalCodeType } from '~/constant/goods-enum';


// 订单、续费路由文件配置
// AvayaIPO
const tableConfig = () => import('~/views/goods/ecology-cloud/avaya-ipo/table.config');
// 常量文件配置
const constant = () => import('~/views/goods/ecology-cloud/avaya-ipo/constant');

// AvayaCC
const tableConfigAvayaCC = () => import('~/views/goods/ecology-cloud/avaya-cc/table.config');
const constantAvayaCC = () => import('~/views/goods/ecology-cloud/avaya-cc/constant');

// AVAYA IPO 购买流程路由
const AvayaIPOPurchaseRouterView: RouteRecordRaw[] = [
  {
    path: 'IPO',
    name: 'avaya-ipo-purchase',
    children: [
      /* 新购 */
      {
        path: 'Purchase',
        name: 'avaya-ipo-purchase-new',
        meta: purchaseMeta,
        component: () => import('~/views/goods/ecology-cloud/avaya-ipo/Purchase.vue'),
      },
      /* 续费 */
      {
        path: 'Renwal/:instance',
        name: 'avaya-ipo-purchase-renwal',
        meta: purchaseMeta,
        component: () => import('~/views/goods/ecology-cloud/avaya-ipo/Renwal.vue'),
      },
      /* 结算 */
      createGoodsBusinessRoute<'settlement'>({
        path: 'Settlement/:orderNum',
        name: 'avaya-ipo-settlement',
        meta: purchaseMeta,
        component: 'settlement',
        props: {
          orderRoute: '/Individual/Order/EcologyCloud/Avaya/IPO',
          orderDetailJsonConfig: { path: constant, property: 'AvayaIPODetailJSONLabel' },
        },
      })
    ]
  }
];
// AVAYA CC 购买流程路由
const AvayaCCPurchaseRouterView: RouteRecordRaw[] = [
  {
    path: 'CC',
    name: 'avaya-cc-purchase',
    children: [
      /* 新购 */
      {
        path: 'Purchase',
        name: 'avaya-cc-purchase-new',
        meta: purchaseMeta,
        component: () => import('~/views/goods/ecology-cloud/avaya-cc/Purchase.vue'),
      },
      /* 续费 */
      {
        path: 'Renwal',
        name: 'avaya-cc-purchase-renwal',
        meta: purchaseMeta,
        component: () => import('~/views/goods/ecology-cloud/avaya-cc/Renwal.vue'),
      }
    ]
  }
];

export const purchase: RouteRecordRaw[] = [
  {
    path: 'Avaya',
    name: 'avaya-purchase',
    children: [...AvayaIPOPurchaseRouterView, ...AvayaCCPurchaseRouterView]
  }
];
export const order: RouteRecordRaw = {
  path: 'Avaya',
  name: 'avaya-order',
  component: createMultipRouterView('avaya-order'),
  redirect: '/Individual/Order/EcologyCloud/Avaya/IPO',
  meta: individualBaseMeta,
  menuProps: {
    name: 'Avaya',
    needExpend: true
  },
  children: [
    createGoodsBusinessRoute<'order'>({
      path: 'IPO',
      name: 'avaya-ipo-order',
      meta: individualBaseMeta,
      menuProps: { name: 'Avaya IPO', show: true },
      component: 'order',
      props: {
        source: 2,
        externalCode: ExternalCodeType.AVAYA_IPO,
        jsonLabel: { path: constant, property: 'AvayaIPODetailJSONLabel' },
        paymentRoute: ({ orderNum }) => `/Avaya/IPO/Settlement/${orderNum}`,
        navigator: {
          list: [
            { text: '订单管理' },
            { text: 'Avaya' },
            { text: 'Avaya IPO' }
          ]
        },
      }
    }),
    createGoodsBusinessRoute<'order'>({
      path: 'CC',
      name: 'avaya-cc-order',
      meta: individualBaseMeta,
      menuProps: { name: 'Avaya CC', show: true },
      component: 'order',
      props: {
        source: 2,
        externalCode: ExternalCodeType.AVAYA_CC,
        verifyOrder: true,
        jsonLabel: { path: constantAvayaCC, property: 'AvayaCCDetailJSONLabel' },
        paymentRoute: row => {
          const { ptId, orderNum } = row;
          return `/Avaya/CC/Purchase?id=${ptId}&orderNum=${orderNum}`;
        },
        navigator: {
          list: [
            { text: '订单管理' },
            { text: 'Avaya' },
            { text: 'Avaya CC' }
          ]
        },
      }
    }),
  ]
};

export const renewal = {
  path: 'Avaya',
  name: 'avaya-instance',
  component: createMultipRouterView('avaya-instance'),
  redirect: '/Individual/Renwal/EcologyCloud/Avaya/IPO',
  meta: individualBaseMeta,
  menuProps: {
    name: 'Avaya',
    needExpend: true
  },
  children: [
    createGoodsBusinessRoute<'renewal'>({
      path: 'IPO',
      name: 'avaya-ipo-instance',
      meta: individualBaseMeta,
      menuProps: { name: 'Avaya IPO', show: true },
      component: 'renewal',
      props: {
        externalCode: ExternalCodeType.AVAYA_IPO,
        filterTable: { cache: true },
        columns: { path: tableConfig, property: 'renewalColumns' },
        navigator: {
          list: [
            { text: '续费管理' },
            { text: 'Avaya' },
            { text: 'Avaya IPO' }
          ]
        }
      }
    }),
    createGoodsBusinessRoute<'renewal'>({
      path: 'CC',
      name: 'avaya-cc-instance',
      meta: individualBaseMeta,
      menuProps: { name: 'Avaya CC', show: true },
      component: 'renewal',
      props: {
        externalCode: ExternalCodeType.AVAYA_CC,
        columns: { path: tableConfigAvayaCC, property: 'instanceColumns' },
        navigator: {
          list: [
            { text: '续费管理' },
            { text: 'Avaya' },
            { text: 'Avaya CC' }
          ]
        }
      }
    }),
  ]
};