import { CurrentSelectParams } from '~/models/response-type';

// 抽取对象中value类型
type InferEnumValueKey<T> = T[keyof T] extends { value: infer V } ? V : never;
// 将类型转为key值化
type EnumProxyReturnKey<T> = T extends EnumValue ? T : never;
// 枚举方法返回值
type EnumProxyResult<T, F = EnumValue> = Record<
  EnumProxyReturnKey<InferEnumValueKey<T>> | keyof T,
  {
    label?: string;
    value: InferEnumValueKey<T>;
    custom?: F;
  }
>;
type EnumValue = string | number;
// 枚举值类型
type EnumItem<T = any> = {
  label?: EnumValue;
  value: EnumValue;
  custom?: T;
};
// 初始化传入枚举类型
export type IncludesArr<T, F> = T & { [key: string | number]: EnumItem<F> };
// 初始化枚举数组类型
const proxyEnum = <T extends object, F = EnumValue>(enumObj: IncludesArr<T, F>) => {
  // 拿key集合
  const keys = Object.keys(enumObj);
  return new Proxy(enumObj, {
    get (t, p) {
      // 判断传入的是数字还是字符串
      const strProp = p.toString();
      const property = Number(strProp === '' ? 'null' : strProp);
      const isNum = !isNaN(property);
      let res = undefined;
      if (isNum) {
        const key = keys.find(f => Number(t[f].value) === property) as keyof typeof t;
        res = t[key];
      } else {
        // 如果是属性
        res = t[p as keyof typeof t];
        // 如果是字符串value
        if (!res) {
          for (const [key, value] of Object.entries(t)) {
            if (value.value === p) {
              res = t[key];
              break;
            }
          }
        }
      }
      return res;
    }
  }) as EnumProxyResult<T, F>;
};
// 将枚举转为label|value
const transProxyEnumArr = <T = CurrentSelectParams, F = any>(
  target: IncludesArr<object, EnumValue | F>,
  labelKey?: keyof T, valueKey?: keyof T
): T[] => {
  return Object.entries(target)?.map(item => {
    const [, val] = item;
    const { label, value } = val;
    const obj = new Object() as T;
    const lkey = labelKey ?? ('label' as keyof typeof obj);
    const vkey = valueKey ?? ('value' as keyof typeof obj);
    obj[lkey] = label as unknown as typeof obj[keyof T];
    obj[vkey] = value as unknown as typeof obj[keyof T];
    return obj;
  });
};
export {
  proxyEnum,
  transProxyEnumArr,
  InferEnumValueKey
};