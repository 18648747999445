import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta } from '~/router/router-meta';

export const purchase: RouteRecordRaw = {
  path: '/MobileCloudBackup',
  name: 'mobile-cbr',
  redirect: '/MobileCloudBackup/Purchase',
  children: [
    // 新购
    {
      path: 'Purchase',
      name: 'cbr-purchase-new',
      component: () => import('~/views/goods/mobile-cloud/purchase/cbr/new/index.vue'),
      meta: purchaseMeta,
    },
  ]
};
