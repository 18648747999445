import { RouteRecordRaw } from 'vue-router';
import { individualBaseMeta, purchaseMeta } from '~/router/router-meta';
import { createGoodsBusinessRoute } from '~/utils/route-generate';
import { ExternalCodeType } from '~/constant/goods-enum';

const constant = () => import('~/views/goods/ecology-cloud/shadow-less/constant');
export const purchase: RouteRecordRaw[] = [
  {
    path: 'ShadowLess',
    name: 'shadow-less-purchase',
    meta: purchaseMeta,
    children: [
      // 新购
      {
        path: 'Purchase',
        name: 'shadow-less-purchase-new',
        meta: purchaseMeta,
        component: () => import('~/views/goods/ecology-cloud/shadow-less/Purchase.vue'),
      },
      // 续费
      {
        path: 'Renewal/:instanceInfo',
        name: 'shadow-less-purchase-renewal',
        meta: purchaseMeta,
        component: () => import('~/views/goods/ecology-cloud/shadow-less/Renewal.vue'),
      },
      // 结算
      createGoodsBusinessRoute<'settlement'>({
        path: 'Settlement/:orderNum',
        name: 'shadow-less-settlement',
        meta: purchaseMeta,
        component: 'settlement',
        props: {
          orderRoute: '/Individual/Order/EcologyCloud/ShadowLess',
          orderDetailJsonConfig: { path: constant, property: 'shadowLessJsonLabel' },
        }
      })
    ]
  },
];


export const order: RouteRecordRaw = createGoodsBusinessRoute<'order'>({
  path: 'ShadowLess',
  name: 'shadow-less-order',
  meta: individualBaseMeta,
  menuProps: { name: '无影云桌面', show: true },
  component: 'order',
  props: {
    externalCode: ExternalCodeType.SHADOWLESS,
    source: 2,
    jsonLabel: { path: constant, property: 'shadowLessJsonLabel' },
    paymentRoute: ({ orderNum }) => `/ShadowLess/Settlement/${orderNum}`,
    navigator: {
      list: [
        { text: '订单管理' },
        { text: '无影云桌面' }
      ]
    },
  }
});


export const renewal: RouteRecordRaw = createGoodsBusinessRoute<'renewal'>({
  path: 'ShadowLess',
  name: 'shadow-less-renewal',
  meta: individualBaseMeta,
  menuProps: { name: '无影云桌面', show: true },
  component: 'renewal',
  props: {
    externalCode: ExternalCodeType.SHADOWLESS,
    columns: { path: constant, property: 'shadowLessRenwalColumns' },
    navigator: {
      list: [
        { text: '续费管理' },
        { text: '无影云桌面' },
      ]
    }
  }
});
