import { RouteRecordRaw } from 'vue-router';
import { purchaseMeta } from '~/router/router-meta';

export const purchase: RouteRecordRaw = {
  path: '/MobilePublickSentiment',
  name: 'mobile-cmopinion',
  redirect: '/MobilePublickSentiment/Purchase',
  children: [
    // 新购
    {
      path: 'Purchase',
      name: 'mobile-cmopinion-new',
      component: () => import('~/views/goods/mobile-cloud/purchase/cmopinion/new/index.vue'),
      meta: purchaseMeta,
    },
  ]
};